import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useReducer, useCallback, useMemo} from 'react';
// utils
import {STORAGE_KEY, TENANT_STORAGE_KEY} from 'src/common';
import axios, {API_ENDPOINTS} from 'src/utils/axios';
//
import {AuthContext} from './auth-context';
import {isValidToken, getTenantId, setSession, setTenant} from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  validToken: false,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      validToken: action.payload.validToken,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      validToken: action.payload.validToken,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      validToken: action.payload.validToken,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      validToken: false,
      user: null,
    };
  }
  if (action.type === 'OTP_GENERATE') {
    return {
      ...state,
      otpData: action.payload.otpData,
    };
  }
  if (action.type === 'OTP_VERIFY') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const tenantIdStorage = sessionStorage.getItem(TENANT_STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(API_ENDPOINTS.auth.me);

        const {user} = response.data;

        if (!tenantIdStorage) {
          setTenant(getTenantId(user));
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            validToken: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            validToken: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          validToken: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username, password) => {
    const data = {
      username,
      password,
    };

    const response = await axios.post(API_ENDPOINTS.auth.login, data);

    const {accessToken, user} = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        validToken: !isEmpty(accessToken),
        user,
      },
    });

    return user;
  }, []);

  // GENERATE OTP
  const otpGenerate = useCallback(async (userId) => {
    const data = {
      user_id: userId
    };

    const response = await axios.post(API_ENDPOINTS.otp.generate, data);

    dispatch({
      type: 'OTP_GENERATE',
      payload: {
        otpData: response.data
      },
    });

  }, []);

  // VERIFY OTP
  const otpVerify = useCallback(async (userId, otpToken) => {
    const data = {
      user_id: userId,
      otp_token: otpToken
    };

    const response = await axios.post(API_ENDPOINTS.otp.verify, data);

    const {otp_verified, user} = response.data;

    dispatch({
      type: 'OTP_VERIFY',
      payload: {
        user,
      },
    });

    return otp_verified;
  }, []);

  // VALIDATE OTP
  const otpValidate = useCallback(async (userId, otpToken) => {
    const data = {
      user_id: userId,
      otp_token: otpToken
    };

    const response = await axios.post(API_ENDPOINTS.otp.validate, data);

    const {accessToken, user} = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        validToken: !isEmpty(accessToken),
        user,
      },
    });

    return user;
  }, []);

  // REGISTER
  const register = useCallback(async (email, username, password, firstName, lastName) => {
    const data = {
      email,
      username,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(API_ENDPOINTS.auth.register, data);

    const {accessToken, user} = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    setTenant(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user && state.validToken ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const otpVerified = state.user && state.user?.otp_enabled && state.user?.otp_verified;

  const mfaEnabled = state.user && state.user?.mfaEnabled;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      mfaEnabled,
      otpVerified,
      otpData: state.otpData,
      //
      login,
      logout,
      register,
      otpValidate,
      otpGenerate,
      otpVerify,
    }),
    [login, logout, register, otpValidate, otpGenerate, otpVerify, state.user, state.otpData, status, otpVerified, mfaEnabled]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
