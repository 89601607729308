import {useTranslation} from 'react-i18next';
import {useEffect, useCallback} from 'react';
import numeral from 'numeral';
// utils
import {LANG_STORAGE_KEY} from 'src/common';
import {localStorageGetItem} from 'src/utils/storage-available';
import {capitalizeFirstLetter, lowerCase} from 'src/utils/change-case';
// components
import {useSettingsContext} from 'src/components/settings';
//
import {allLangs, defaultLang, numeralLocalesConfig} from './config-lang';

// ----------------------------------------------------------------------

// Registrar locales de forma segura
const registerLocale = (locale, config) => {
  if (!numeral.locales[locale]) {
    numeral.register('locale', locale, config);
  }
};

export default function useLocales() {
  const {i18n, t} = useTranslation();

  const settings = useSettingsContext();

  const langStorage = localStorageGetItem(LANG_STORAGE_KEY);

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const onChangeLang = useCallback(
    (newlang) => {
      i18n.changeLanguage(newlang);
      settings.onChangeDirectionByLang(newlang);
    },
    [i18n, settings]
  );

  // Registrar e definir locale para numeral.js
  useEffect(() => {
    const localeKey = currentLang.value || 'en-us';

    // Registrar os locales se ainda não estiverem registrados
    Object.entries(numeralLocalesConfig).forEach(([locale, config]) => {
      registerLocale(locale, config);
    });

    // Definir o locale do numeral.js conforme o idioma atual
    numeral.locale(localeKey);

  }, [currentLang]);


  // Registrar locais para numeral.js
  useEffect(() => {
    // Definir o locale do numeral.js conforme o idioma atual
    numeral.locale(currentLang.locale);
  }, [currentLang]);

  // translate and capitalize first letter
  const tc = (key) => capitalizeFirstLetter(t(lowerCase(key)));

  const tlc = (key) => lowerCase(t(lowerCase(key)));

  return {
    allLangs,
    t,
    tc,
    tlc,
    currentLang,
    onChangeLang,
  };
}
