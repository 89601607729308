import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
// locales
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  bug_duotone: icon('ic_bug_bold_duotone'),
  category_duotone: icon('ic_category_duotone'),
  layers_duotone: icon('ic_layers_duotone'),
  copy_duotone: icon('ic_copy_duotone'),
  setting: icon('ic_setting'),
  user_id_duotone: icon('ic_user_id_duotone'),
  user_check_duotone: icon('ic_user_check_duotone'),
  tag_price_duotone: icon('ic_tag_price_duotone'),
  money_bag_duotone: icon('ic_money_bag_duotone'),
  dollar_duotone: icon('ic_dollar_duotone'),
  box_duotone: icon('ic_box_duotone'),
  widget_duotone: icon('ic_widget_duotone'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const {t, tc } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
        ],
      },
      // PENTEST
      // ----------------------------------------------------------------------
      // {
      //   subheader: t('pentest'),
      //   items: [
      //     {
      //       title: t('reports'),
      //       path: paths.pentest.report.root,
      //       icon: ICONS.file,
      //       children: [
      //         { title: t('list'), path: paths.pentest.report.root },
      //         { title: t('create'), path: paths.pentest.report.create },
      //       ],
      //     },
      //     {
      //       title: t('vulnerabilities'),
      //       path: paths.pentest.vulnerability.root,
      //       icon: ICONS.bug_duotone,
      //       children: [
      //         { title: t('list'), path: paths.pentest.vulnerability.root },
      //         { title: t('create'), path: paths.pentest.vulnerability.create },
      //       ],
      //     },
      //   ],
      // },
      // SALES
      // ----------------------------------------------------------------------
      {
        subheader: t('sales'),
        items: [
          {
            title: t('orders'),
            path: paths.dashboard.blank,
            icon: ICONS.order,
            // children: [
            //   { title: t('list'), path: paths.pentest.report.root },
            //   { title: t('create'), path: paths.pentest.report.create },
            // ],
          },
          {
            title: t('stocks'),
            path: paths.sales.stock.root,
            icon: ICONS.widget_duotone,
          },
        ],
      },
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          {
            title: t('company'),
            path: paths.management.company.root,
            icon: ICONS.banking,
            // children: [
            //   { title: t('list'), path: paths.management.company.root },
            //   { title: t('create'), path: paths.management.company.create },
            // ],
          },
          {
            title: t('supervisor'),
            path: paths.management.supervisor.root,
            icon: ICONS.job
          },
          {
            title: t('seller'),
            path: paths.management.seller.root,
            icon: ICONS.user_id_duotone
          },
          {
            title: t('client'),
            path: paths.management.client.root,
            icon: ICONS.user_check_duotone
          },
          {
            title: t('product'),
            path: paths.management.product.root,
            icon: ICONS.box_duotone,
          },
          // {
          //   title: t('methodology'),
          //   path: paths.management.methodology.root,
          //   icon: ICONS.tour,
          // },
          // {
          //   title: t('vulnerability_templates'),
          //   path: paths.management.vulnerabilityTemplate.root,
          //   icon: ICONS.copy_duotone,
          //   children: [
          //     { title: t('list'), path: paths.management.vulnerabilityTemplate.root },
          //     { title: t('create'), path: paths.management.vulnerabilityTemplate.create },
          //   ],
          // },
          // {
          //   title: t('vulnerability_categories'),
          //   path: paths.management.vulnerabilityCategory.root,
          //   icon: ICONS.category_duotone,
          //   caption: t('vulnerability_categories_description'),
          // },
          // {
          //   title: t('vulnerability_types'),
          //   path: paths.management.vulnerabilityType.root,
          //   icon: ICONS.layers_duotone,
          //   caption: t('vulnerability_types_description')
          // },
          // {
          //   title: t('cwe'),
          //   path: paths.management.cwe.root,
          //   icon: ICONS.blog,
          //   caption: t('common_weakness_enumeration'),
          // },
        ],
      },
      // POLICIES
      // ----------------------------------------------------------------------
      {
        subheader: t('policies'),
        items: [
          {
            title: t('pricing'),
            path: paths.management.pricing.root,
            icon: ICONS.tag_price_duotone,
            caption: tc('pricing_of_products_or_services'),
          },
          {
            title: t('price_table'),
            path: paths.management.priceTable.root,
            icon: ICONS.money_bag_duotone,
            caption: tc('registration_of_price_tables'),
          },
          {
            title: t('payment_methods'),
            path: paths.management.paymentMethod.root,
            icon: ICONS.dollar_duotone,
            caption: tc('registration_of_payment_methods'),
          },
          {
            title: t('payment_terms'),
            path: paths.management.paymentTerm.root,
            icon: ICONS.layers_duotone,
            caption: tc('registration_of_payment_terms'),
          }
        ],
      },
      // SETTINGS
      // ----------------------------------------------------------------------
      {
        subheader: t('settings'),
        items: [
          {
            title: t('user'),
            path: paths.management.user.root,
            icon: ICONS.user,
          }, 
          // {
          //   title: t('parameters'),
          //   path: paths.management.user.root,
          //   icon: ICONS.setting,
          // },
          // {
          //   title: t('blank'),
          //   path: paths.dashboard.blank,
          //   icon: ICONS.blank,
          // },
        ],
      },
    ],
    [t, tc]
  );

  return data;
}
