// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  SALES: '/sales',
  PENTEST: '/pentest',
  MANAGEMENT: '/management',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  cweMitre:'https://cwe.mitre.org/data/definitions/',
  erros: {
    error_500:'/500',
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
    otp: {
      generate: `${ROOTS.AUTH}/otp/generate`,
      verify: `${ROOTS.AUTH}/otp/verify`,
      validate: `${ROOTS.AUTH}/otp/validate`,
      disable: `${ROOTS.AUTH}/otp/disable`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    blank: `${ROOTS.DASHBOARD}/blank`,
  },
  // PENTEST
  pentest: {
    root: ROOTS.PENTEST,
    report: {
      root: `${ROOTS.PENTEST}/reports`,
      create: `${ROOTS.PENTEST}/reports/create`,
      details:  (id) => `${ROOTS.PENTEST}/reports/${id}`,
      edit:  (id) => `${ROOTS.PENTEST}/reports/${id}/edit`,
    },
    vulnerability: {
      root: `${ROOTS.PENTEST}/vulnerabilities`,
      create: `${ROOTS.PENTEST}/vulnerabilities/create`,
      details:  (id) => `${ROOTS.PENTEST}/vulnerabilities/${id}`,
      edit:  (id) => `${ROOTS.PENTEST}/vulnerabilities/${id}/edit`,
    },
  },
  // SALES
  sales: {
    root: ROOTS.SALES,
    stock: {
      root: `${ROOTS.SALES}/stock`,
      create: `${ROOTS.SALES}/stock/create`,
      details:  (id) => `${ROOTS.SALES}/stock/${id}`,
      edit:  (id) => `${ROOTS.SALES}/stock/${id}/edit`,
    },
  },
  // MANAGEMENT
  management: {
    root: ROOTS.MANAGEMENT,
    user: {
      root: `${ROOTS.MANAGEMENT}/user`,
      create: `${ROOTS.MANAGEMENT}/user/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/user/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/user/${id}/edit`,
    },
    company: {
      root: `${ROOTS.MANAGEMENT}/company`,
      create: `${ROOTS.MANAGEMENT}/company/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/company/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/company/${id}/edit`,
    },
    client: {
      root: `${ROOTS.MANAGEMENT}/client`,
      create: `${ROOTS.MANAGEMENT}/client/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/client/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/client/${id}/edit`,
    },
    product: {
      root: `${ROOTS.MANAGEMENT}/product`,
      create: `${ROOTS.MANAGEMENT}/product/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/product/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/product/${id}/edit`,
    },
    supervisor: {
      root: `${ROOTS.MANAGEMENT}/supervisor`,
      create: `${ROOTS.MANAGEMENT}/supervisor/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/supervisor/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/supervisor/${id}/edit`,
    },
    seller: {
      root: `${ROOTS.MANAGEMENT}/seller`,
      create: `${ROOTS.MANAGEMENT}/seller/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/seller/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/seller/${id}/edit`,
    },
    paymentMethod: {
      root: `${ROOTS.MANAGEMENT}/payment-method`,
      create: `${ROOTS.MANAGEMENT}/payment-method/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/payment-method/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/payment-method/${id}/edit`,
    },
    paymentTerm: {
      root: `${ROOTS.MANAGEMENT}/payment-term`,
      create: `${ROOTS.MANAGEMENT}/payment-term/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/payment-term/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/payment-term/${id}/edit`,
    },
    methodology: {
      root: `${ROOTS.MANAGEMENT}/methodology`,
      create: `${ROOTS.MANAGEMENT}/methodology/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/methodology/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/methodology/${id}/edit`,
    },
    cwe: {
      root: `${ROOTS.MANAGEMENT}/cwe`,
      create: `${ROOTS.MANAGEMENT}/cwe/create`,
      details: `${ROOTS.MANAGEMENT}/cwe/:id`,
      edit: `${ROOTS.MANAGEMENT}/cwe/:id/edit`,
    },
    vulnerability: {
      root: `${ROOTS.MANAGEMENT}/vulnerability`,
      category: `${ROOTS.MANAGEMENT}/vulnerability/category`,
      type: `${ROOTS.MANAGEMENT}/vulnerability/type`,
      template: `${ROOTS.MANAGEMENT}/vulnerability/template`,
    },
    vulnerabilityCategory: {
      root: `${ROOTS.MANAGEMENT}/vulnerability/category`,
      create: `${ROOTS.MANAGEMENT}/vulnerability/category/create`,
      details: `${ROOTS.MANAGEMENT}/vulnerability/category/:id`,
      edit: `${ROOTS.MANAGEMENT}/vulnerability/category/:id/edit`,
    },
    vulnerabilityType: {
      root: `${ROOTS.MANAGEMENT}/vulnerability/type`,
      create: `${ROOTS.MANAGEMENT}/vulnerability/type/create`,
      details: `${ROOTS.MANAGEMENT}/vulnerability/type/:id`,
      edit: `${ROOTS.MANAGEMENT}/vulnerability/type/:id/edit`,
    },
    vulnerabilityTemplate: {
      root: `${ROOTS.MANAGEMENT}/vulnerability/template`,
      create: `${ROOTS.MANAGEMENT}/vulnerability/template/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/vulnerability/template/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/vulnerability/template/${id}/edit`,
      clone: (id) => `${ROOTS.MANAGEMENT}/vulnerability/template/${id}/clone`,
    },
    pricing: {
      root: `${ROOTS.MANAGEMENT}/pricing`,
      create: `${ROOTS.MANAGEMENT}/pricing/create`,
      details: `${ROOTS.MANAGEMENT}/pricing/:id`,
      edit: `${ROOTS.MANAGEMENT}/pricing:id/edit`,
    },
    priceTable: {
      root: `${ROOTS.MANAGEMENT}/price-table`,
      create: `${ROOTS.MANAGEMENT}/price-table/create`,
      details: (id) => `${ROOTS.MANAGEMENT}/price-table/${id}`,
      edit: (id) => `${ROOTS.MANAGEMENT}/price-table/${id}/edit`,
    },
  }
};
