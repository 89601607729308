import axios from 'axios';
import {LANGUAGE_HEADER_KEY, TENANT_HEADER_KEY} from 'src/common';
// config
import { HOST_API, PATH_ERROR_500 } from 'src/config-global';
import {localStorageGetLangBrowser, sessionStorageGetTenantId} from './storage-available';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const tenantIdStorage = sessionStorageGetTenantId();
    const langStorage = localStorageGetLangBrowser();

    config.headers[TENANT_HEADER_KEY] =  tenantIdStorage;
    config.headers[LANGUAGE_HEADER_KEY] =  langStorage;
    return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 500) {
      window.location.href = PATH_ERROR_500
    }
    // if (error.response.status == 403) {
    //   refreshToken()
    // }
    return Promise.reject((error.response) || 'Something went wrong')
  }
);

export default axiosInstance;

export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/v1/auth/me',
    login: '/v1/auth/login',
    register: '/v1/auth/register',
  },
  otp: {
    generate: '/v1/otp/generate',
    validate: '/v1/otp/validate',
    verify: '/v1/otp/verify',
    disable: '/v1/otp/disable',
  },
  dashboard: {
    get: '/v1/dashboard',
  },
  report: {
    create: '/v1/reports',
    list: '/v1/reports',
    update: '/v1/reports',
    get: '/v1/reports',
    details: '/v1/reports/details',
    search: '/v1/reports/search',
    csv: (id) => `/v1/reports/${id}/export/csv`,
    pdf: (id) => `/v1/reports/${id}/export/pdf`,
    html: (id) => `/v1/reports/${id}/export/html`,
  },
  vulnerability: {
    create: '/v1/vulnerabilities',
    list: '/v1/vulnerabilities',
    update: '/v1/vulnerabilities',
    get: '/v1/vulnerabilities',
    details: '/v1/vulnerabilities/details',
    search: '/v1/vulnerabilities/search',
    csv: '/v1/vulnerabilities/export/csv',
  },
  user: {
    create: '/v1/users',
    list: '/v1/users',
    update: '/v1/users',
    update_password: '/v1/users/change-password',
    update_permissions: '/v1/users/permissions',
    get: '/v1/users',
    details: '/v1/users/details',
    search: '/v1/users/search',
  },
  company: {
    create: '/v1/companies',
    list: '/v1/companies',
    update: '/v1/companies',
    get: '/v1/companies',
    details: '/v1/companies/details',
    search: '/v1/companies/search',
  },
  client: {
    create: '/v1/clients',
    list: '/v1/clients',
    update: '/v1/clients',
    get: '/v1/clients',
    details: '/v1/clients/details',
    search: '/v1/clients/search',
  },
  product: {
    create: '/v1/products',
    list: '/v1/products',
    update: '/v1/products',
    get: '/v1/products',
    details: '/v1/products/details',
    search: '/v1/products/search',
  },
  supervisor: {
    create: '/v1/supervisors',
    list: '/v1/supervisors',
    update: '/v1/supervisors',
    get: '/v1/supervisors',
    details: '/v1/supervisors/details',
    search: '/v1/supervisors/search',
  },
  group: {
    create: '/v1/groups',
    list: '/v1/groups',
    update: '/v1/groups',
    get: '/v1/groups',
    details: '/v1/groups/details',
    search: '/v1/groups/search',
  },
  seller: {
    create: '/v1/sellers',
    list: '/v1/sellers',
    update: '/v1/sellers',
    get: '/v1/sellers',
    details: '/v1/sellers/details',
    search: '/v1/sellers/search',
  },
  stock: {
    create: '/v1/stocks',
    list: '/v1/stocks',
    update: '/v1/stocks',
    get: '/v1/stocks',
    details: '/v1/stocks/details',
    search: '/v1/stocks/search',
  },
  paymentMethod: {
    create: '/v1/payment-methods',
    list: '/v1/payment-methods',
    update: '/v1/payment-methods',
    get: '/v1/payment-methods',
    details: '/v1/payment-methods/details',
    search: '/v1/payment-methods/search',
  },
  paymentTerm: {
    create: '/v1/payment-terms',
    list: '/v1/payment-terms',
    update: '/v1/payment-terms',
    get: '/v1/payment-terms',
    details: '/v1/payment-terms/details',
    search: '/v1/payment-terms/search',
  },
  priceTable: {
    create: '/v1/price-tables',
    list: '/v1/price-tables',
    update: '/v1/price-tables',
    get: '/v1/price-tables',
    details: '/v1/price-tables/details',
    search: '/v1/price-tables/search',
  },
  pricing: {
    create: '/v1/prices',
    list: '/v1/prices',
    update: '/v1/prices',
    get: '/v1/prices',
    details: '/v1/prices/details',
    search: '/v1/prices/search',
  },
  methodology: {
    create: '/v1/methodologies',
    list: '/v1/methodologies',
    update: '/v1/methodologies',
    get: '/v1/methodologies',
    details: '/v1/methodologies/details',
    search: '/v1/methodologies/search',
  },
  cwe: {
    list: '/v1/cwes',
    details: '/v1/cwes/details',
    search: '/v1/cwes/search',
  },
  vulnerabilityCategory: {
    create: '/v1/vulnerability-categories',
    list: '/v1/vulnerability-categories',
    update: '/v1/vulnerability-categories',
    details: '/v1/vulnerability-categories/details',
    search: '/v1/vulnerability-categories/search',
  },
  vulnerabilityType: {
    create: '/v1/vulnerability-types',
    list: '/v1/vulnerability-types',
    update: '/v1/vulnerability-types',
    details: '/v1/vulnerability-types/details',
    search: '/v1/vulnerability-types/search',
  },
  vulnerabilityTemplate: {
    create: '/v1/vulnerability-templates',
    list: '/v1/vulnerability-templates',
    update: '/v1/vulnerability-templates',
    get: '/v1/vulnerability-templates',
    details: '/v1/vulnerability-templates/details',
    search: '/v1/vulnerability-templates/search',
  },
};
