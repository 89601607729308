// @mui
import { ptBR, enUS
} from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Português (BR)',
    value: 'ptBR',
    systemValue: ptBR,
    locale: 'pt-BR',
    icon: 'flagpack:br',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    locale: 'en-US',
    icon: 'flagpack:us',
  },
];

// Configurações dos locales
export const numeralLocalesConfig = {
  'pt-br': {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    ordinal(number) {
      return number === 1 ? 'er' : 'th';
    },
    currency: {
      symbol: 'R$'
    }
  },
  'en-us': {
    delimiters: {
      thousands: ',',
      decimal: '.'
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    ordinal(number) {
      return number === 1 ? 'st' : 'th';
    },
    currency: {
      symbol: '$'
    }
  }
};

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
