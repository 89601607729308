import {Suspense, lazy} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const ReportCreatePage = lazy(() => import('src/pages/pentest/report/create'));
const ReportListPage = lazy(() => import('src/pages/pentest/report/list'));
const ReportEditPage = lazy(() => import('src/pages/pentest/report/edit'));
const ReportDetailsPage = lazy(() => import('src/pages/pentest/report/details'));
// ----------------------------------------------------------------------
const StocktListPage = lazy(() => import('src/pages/sales/stock/list'))
const StockEditPage = lazy(() => import('src/pages/sales/stock/edit'))
// ----------------------------------------------------------------------

export const salesRoutes = [
  {
    path: 'sales',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'order',
        children: [
          {element: <ReportListPage />, index: true},
          {path: 'list', element: <ReportListPage />},
          {path: ':id', element: <ReportDetailsPage />},
          {path: ':id/edit', element: <ReportEditPage />},
          {path: 'create', element: <ReportCreatePage />},
        ],
      },
      {
        path: 'stock',
        children: [
          {element: <StocktListPage />, index: true},
          {path: 'list', element: <StocktListPage />},
          {path: ':id', element: <StockEditPage />},
          {path: ':id/edit', element: <StockEditPage />},
          {path: 'create', element: <StockEditPage />},
        ],
      },
    ],
  },
];
